<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <div class="BoxHeight2 plr13 pb0">
      <!-- 发布记录 -->
      <div class="publishList pt15" v-if="examList.length > 0">
        <div
          class="item bbox mb10"
          v-for="exam in examList"
          :key="exam.examCode"
          @click="gotoDetail(exam.examCode, exam.title)"
        >
          <div class="text-cut">{{ exam.title }}</div>
          <div class="f12 text999">
            {{ $t("my.publishTime") }}：{{ exam.startDt | dataFormat }}
          </div>
          <div class="f12 text999">
            {{ $t("my.examDates") }}：{{ exam.endDt | dataFormat }}
          </div>
        </div>
      </div>
      <!-- 结果为空 -->
      <div v-else class="kong positiona">
        <img src="../../assets/images/kong.png" alt="" width="150" />
        <div class="f15 textc mt15 text999">{{ $t("my.noTestLogging") }}~</div>
      </div>
    </div>
    <!-- 页脚 -->
    <Footer class="positionf footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      examList: [],
      historyExamList: [],
      currPage: 1,
      totalPage: 1,
      pageSize: 30,
      showLoading: false,
      isTestRecord: "1",
    };
  },
  methods: {
    //跳转到培训详情
    gotoDetail(examCode, title) {
      this.$router.push({
        path: "/testDetail",
        query: {
          examCode: examCode,
          title: title,
        },
      });
    },
    onScroll() {
      console.log("滚动了");
      // 可滚动容器的高度
      var innerHeight = document.querySelector(".BoxHeight2").clientHeight;
      // 屏幕尺寸高度
      var outerHeight = document.documentElement.clientHeight - 70;
      // 可滚动容器超出当前窗口显示范围的高度
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight <= (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      console.log(
        "可滚动容器的高度:" + innerHeight,
        "屏幕尺寸高度:" + outerHeight,
        "可滚动容器超出当前窗口显示范围的高度:" + scrollTop
      );
      if (outerHeight + scrollTop >= innerHeight) {
        console.log("触底了");
        // 加载更多操作
        this.currPage++;
        this.loadHistoryExamList();
      }
    },
    loadExamList() {
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberExam/queryExamList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            that.examList = res.data.data;
          }
        });
    },
    loadHistoryExamList() {
      this.showLoading = true;
      var sendData = {};
      sendData.currPage = this.currPage;
      sendData.pageSize = this.pageSize;
      sendData.memberCode = localStorage.getItem("memberCode");
      var that = this;
      this.$http
        .post("memberExam/queryHistoryExamList", sendData)
        .then(function (res) {
          if (200 == res.data.code) {
            // that.historyExamList = res.data.data.data;
            for (var i = 0; i < res.data.data.data.length; i++) {
              that.historyExamList.push(res.data.data.data[i]);
            }
            that.currPage = res.data.data.currPage;
            that.totalPage = res.data.data.pageCount;
            that.showLoading = false;
          }
        });
    },
  },
  created() {
    this.loadExamList();
    this.loadHistoryExamList();
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
    this.isTestRecord = this.$route.query.isTestRecord;
  },
  mounted() {
    // 监听滚动事件
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.item {
  background: #f8f8f8;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 8px 12px;
}
.footer {
  bottom: 0;
  left: 0;
  width: 100vw;
}
</style>